<template>
  <ion-page>
    <ion-header>
      <ion-grid fixed style="padding: 0">

        <!-- Place types filter -->
        <ion-toolbar style="margin-top: 5px">
          <!-- Back button -->
          <ion-buttons slot="start" v-if="parentPath">
            <ion-back-button class="back-icon" :default-href="parentPath"></ion-back-button>
          </ion-buttons>
          
          <!-- Place Type Filter -->
          <ion-segment v-model="typeFilter" @ionChange="onTypeChanged($event.target.value, placeTypes)" scrollable>
            <ion-segment-button v-for="(type, i) in placeTypes" :key="type.id" :value="type.title" :ref="el => { segmentBtns[i] = el }">
              <ion-avatar style="width: 56px; height: 48px;">
                <img style="width: 56px; height: 56px;" :src="type.imageLink">
              </ion-avatar>
              <ion-label>{{ getLocalizedStr(type, 'title', 'titleEn') }}</ion-label>
            </ion-segment-button>
          </ion-segment>
        </ion-toolbar>

        <ion-toolbar>
          <!--
          <ion-buttons slot="start" v-if="parentPath">
            <ion-back-button class="back-icon" :default-href="parentPath"></ion-back-button>
          </ion-buttons>
          -->
          <ion-title color="secondary" slot="start" style="flex: initial">
            {{ typeFilter }}
          </ion-title>
          <ion-searchbar ref="placeSearchBar" class="place-searchbar" mode="ios" v-model="searchKeyword"
                        :placeholder="isListening ? t('listening') : t('search')"
                        @ionFocus="isSearching = true" @ionBlur="isSearching = false" @keyup.enter="(e) => e.target.blur()"></ion-searchbar>
          <ion-buttons slot="end">
            <ion-button slot="icon-only" @touchstart="startListening" @touchend="stopListening" v-if="iPhoneNativeApp">
              <ion-icon :icon="mic"></ion-icon>
            </ion-button>
            <ion-button slot="icon-only" @click="startListening" v-else>
              <ion-icon :icon="mic"></ion-icon>
            </ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-grid>
    </ion-header>

    <ion-content>

      <ion-grid fixed>
        
        <div id="top-anchor" style="position: absolute; top: -100px; left: 0"></div>

        <!-- Loading Data -->
        <loading-skeleton v-if="loading || delayLoad"></loading-skeleton>

        <!-- Data loaded -->
        <div v-else>

          <!--<swiper
            @swiper="setSwiperInstance"
            @slideChangeTransitionStart="onSlideChanged($event, placeTypes)"
            :slides-per-view="1"
            :loop="true"
            :longSwipesRatio="0.85"
          >
            <swiper-slide>-->
                <!-- 24小時營業 -->
                <div ref="topElRef" v-show="['獸醫', '善終', '全部', '寵物寄宿', '領養'].includes(typeFilter)">
                  <slides :places="filterPlaces('24小時營業')" title="24小時營業" :typeFilter="typeFilter"></slides>
                </div>

                <!-- 最短距離 -->
                <slides :places="filterPlaces('最短距離')" title="最短距離" :typeFilter="typeFilter"></slides>

                <!-- 最高評分 -->
                <slides :places="filterPlaces('最高評分')" title="最高評分" :typeFilter="typeFilter"></slides>

                <!-- 最低評分 -->
                <slides :places="filterPlaces('最低評分')" title="最低評分" :typeFilter="typeFilter"></slides>

                <!-- 最多人評論 -->
                <slides :places="filterPlaces('最多人評論')" title="最多人評論" :typeFilter="typeFilter"></slides>

                <!-- 最多人 Likes -->
                <slides :places="filterPlaces('最多人 Like / Follow')" title="最多人 Like / Follow" :typeFilter="typeFilter"></slides>

                <!-- 最耐歷史 -->
                <slides :places="filterPlaces('最耐歷史')" title="最耐歷史" :typeFilter="typeFilter" :hideDivider="true"></slides>
                <!--
            </swiper-slide>
          </swiper>-->

          <div class="section-divider"></div>

          <!-- Button to 所有地點 -->
          <div class="ion-margin-vertical ion-text-center">
            <ion-button shape="round" color="tertiary" router-link="/places">
              {{ t('seeMorePlaces') }}
              <ion-icon slot="end" :icon="arrowForward"></ion-icon>
            </ion-button>
          </div>

        </div>

      </ion-grid>

    </ion-content>
  </ion-page>
</template>

<script>
// Vue reactivity
import { computed, ref, watch } from 'vue';

// icons
import { add, close, search, navigate, arrowBack, bookmarksOutline, timeOutline,
         swapVerticalOutline, trashOutline, arrowForward, location, mic, stopCircleOutline, } from 'ionicons/icons';

// components
import 'swiper/swiper.min.css';
import '@ionic/vue/css/ionic-swiper.css';
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue';

import { IonicSlides, IonPage, IonHeader, IonSearchbar, IonToolbar, IonTitle, IonContent,
        IonGrid, IonRow, IonCol, IonList, IonItem, IonLabel, IonIcon,
        IonThumbnail, IonButtons, IonButton, IonModal, IonImg,
        IonSegment, IonSegmentButton, IonChip, IonBackButton, IonCheckbox, IonBadge,
        IonInfiniteScroll, IonInfiniteScrollContent, IonAvatar, IonSelect, IonSelectOption,
        loadingController, onIonViewDidEnter, isPlatform, } from '@ionic/vue';
import LoadingSkeleton from "@/components/LoadingSkeleton.vue";
import PlaceItem from "@/components/PlaceItem.vue";
import Slides from '@/components/slides/Slides.vue';
import SectionHeader from "@/components/SectionHeader.vue";

// composables
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { utils } from '@/composables/utils';
import { useRoute } from 'vue-router';

import { SpeechRecognition } from '@awesome-cordova-plugins/speech-recognition';
import { Vibration } from '@awesome-cordova-plugins/vibration';

export default {
  name: 'PlaceOverviewPage',
  components: { IonHeader, IonSearchbar, IonToolbar, IonTitle, IonContent, IonPage,
                IonGrid, IonRow, IonCol, IonList, IonItem, IonLabel, IonIcon,
                IonThumbnail, IonButtons, IonButton, IonCheckbox, IonBadge, IonModal, IonImg,
                IonSegment, IonSegmentButton, IonChip, IonBackButton,
                IonInfiniteScroll, IonInfiniteScrollContent, IonAvatar, IonSelect, IonSelectOption, 
                LoadingSkeleton, PlaceItem, Slides, SectionHeader,
                Swiper, SwiperSlide, },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const route = useRoute();
    const { getLocalizedStr, getTypeColor, syncFilterBtnPosition, sleep, shuffleArray, iPhoneNativeApp } = utils();

    // 1. declare state variables (ref to make them reactive)
    const { type: preSelectTypeId, parentPath } = history.state;

    const currUser = computed(() => store.state.user);
    const userLatLong = computed(() => store.state.userLatLong);
    const loading = computed(() => store.state.loadingAppPublicData);
    const allPlaces = computed(() => store.state.places);
    const placeTypes = computed(() => store.state.placeTypes);

    let firstTimeEntered = false;
    const places = ref(allPlaces.value); // filtered products
    const typeFilter = ref("全部");
    const segmentBtns = ref([]);
    const slides = ref();
    const topElRef = ref(null);
    const delayLoad = ref(true);
    const setSwiperInstance = (swiper) => slides.value = swiper;

    const onTypeChanged = async (newVal, allTypes) => {
      //const targetIdx = allTypes.findIndex(t => t.title == newVal);
      //slides.value.slideTo(targetIdx);
      //syncFilterBtnPosition(segmentBtns.value, typeFilter.value);
      document.querySelector(`#top-anchor`).scrollIntoView(true);
    }
    const onSlideChanged = async (e, allTypes) => {
      if (!firstTimeEntered) {
        firstTimeEntered = true;
      } else {
        const loading = await loadingController.create({});
        await loading.present();

        const currTypeIdx = allTypes.findIndex(t => t.title == typeFilter.value);

        // check slide prev / next
        const nextTypeIdx = (e.activeIndex < e.previousIndex ? currTypeIdx-1 : currTypeIdx+1);
        
        // go back to beginning if reach to end
        const nextTypeObj = nextTypeIdx > allTypes.length-1 ? allTypes[0]
                            : (nextTypeIdx < 0 ? allTypes[allTypes.length-1] : allTypes[nextTypeIdx]);
        typeFilter.value = nextTypeObj.title;
        
        //typeFilter.value = (allTypes[e.activeIndex] || {}).title;
        syncFilterBtnPosition(segmentBtns.value, typeFilter.value);

        await sleep(0.5);
        loading.dismiss();
      }
    }

    const searchKeyword = ref("");
    const placeSearchBar = ref(null);
    const isSearching = ref(false);

    const filterPlaces = (filterMethod) => {
      let resultPlaces = places.value.filter(place => {
        return !place.permClosed && (typeFilter.value == '全部' || place['種類'].includes(typeFilter.value));
      });
      if (filterMethod) {
        let sortKey = null, order = 'asc';
        if (filterMethod == '最短距離') {
          sortKey = 'distanceVal';
        }
        else if (filterMethod == '24小時營業') {
          resultPlaces = resultPlaces.filter(p => p['24小時營業?'] == 'Y');
          if (resultPlaces[0] && resultPlaces[0]['distanceVal']) {
            sortKey = 'distanceVal';
          } else {
            resultPlaces = shuffleArray(resultPlaces);
          }
        }
        else if (filterMethod == '最高評分') {
          //sortKey = 'avgSMPRating';
          //order = 'desc';
          resultPlaces.sort((a, b) => {
            const ratingA = Number(a['avgSMPRating']), ratingB = Number(b['avgSMPRating']);
            return ratingA > ratingB ? -1 : (ratingA < ratingB ? 1 : (Number(a['avgSMPRatingUserTotal']) > Number(b['avgSMPRatingUserTotal']) ? -1 : 1));
          });
        }
        else if (filterMethod == '最低評分') {
          resultPlaces.sort((a, b) => {
            if (a['avgSMPRating'] == "") return 1;
            if (b['avgSMPRating'] == "") return -1; // put no rating places to back
            const numberA = Number(a['avgSMPRating']), numberB = Number(b['avgSMPRating']);
            return numberA < numberB ? -1 : (numberA > numberB ? 1 : (Number(a['avgSMPRatingUserTotal']) > Number(b['avgSMPRatingUserTotal']) ? -1 : 1));
          });
        }
        else if (filterMethod == '最多人 Like / Follow') {
          sortKey = 'totalSMPLikes';
          order = 'desc';
        }
        else if (filterMethod == '最多人評論') {
          sortKey = 'avgSMPRatingUserTotal';
          order = 'desc';
        }
        else if (filterMethod == '最耐歷史') {
          sortKey = 'yearFounded';
        }
        if (sortKey) {
          resultPlaces = resultPlaces.filter(p => p[sortKey]); // filter out places with out the sort key val
          if (order == 'asc') {
            resultPlaces.sort((a, b) => (Number(a[sortKey]) < Number(b[sortKey]) ? -1 : (Number(a[sortKey]) > Number(b[sortKey]) ? 1 : 0)));
          } else {
            resultPlaces.sort((a, b) => (Number(a[sortKey]) > Number(b[sortKey]) ? -1 : (Number(a[sortKey]) < Number(b[sortKey]) ? 1 : 0)));
          }
        }
      }
      return resultPlaces.slice(0, 15);
    }

    const checkMatchKeyword = (obj, keyword) => {
      return `${obj["大區"]} ${obj["地區"]} ${obj["細區"]} ${obj["名稱"].toLowerCase()}`.includes(keyword.toLowerCase());
    }
    watch(searchKeyword, (currKeyword) => {
      const keyword = currKeyword.toLowerCase();
      places.value = allPlaces.value.filter(obj => checkMatchKeyword(obj, keyword));
    });
    watch(allPlaces, (currPlaces) => { // triggered only when direct access to products page
      places.value = currPlaces.filter(obj => checkMatchKeyword(obj, searchKeyword.value));
    });

    onIonViewDidEnter(() => {
      if (preSelectTypeId) { // first enter
        //onTypeChanged(preSelectTypeId, placeTypes.value);
        typeFilter.value = preSelectTypeId;
        syncFilterBtnPosition(segmentBtns.value, typeFilter.value);
      }
      setTimeout(() => {
        delayLoad.value = false;
      }, 1000);
      
      document.querySelector(`#top-anchor`).scrollIntoView(true);
    })

    /**
     * Speech to text recognition
     */
    const isListening = ref(false);
    let hasPermission, pressTimer = null;
    
    const startListening = async () => {
      if (pressTimer == null) {
        Vibration.vibrate(1000);
        // empty the existing searching word first
        searchKeyword.value = "";
        isListening.value = true;

        pressTimer = setTimeout(async () => {
          // start listening
          if (hasPermission == null) {
            hasPermission = await SpeechRecognition.hasPermission();
            if (!hasPermission) {
              await SpeechRecognition.requestPermission();
              hasPermission = true;
            }
          }
          SpeechRecognition.startListening({
            language: isPlatform('android') ? "yue-Hant-HK" : "yue-CN",
            showPartial: true,
          }).subscribe(
            matches => {
              console.log(matches);
              searchKeyword.value = matches[0];
            },
            error => console.log('error:', error)
          );
        }, 10)
      }
    }
    const stopListening = async () => {
      if (isListening.value == true) {
        isListening.value = false;
        if (pressTimer) await SpeechRecognition.stopListening();
      }
      if (pressTimer) {
        clearTimeout(pressTimer);
        pressTimer = null;
      }
    }

    // 3. return variables & methods to be used in template HTML
    return {
      // methods
      t, getLocalizedStr, getTypeColor,
      filterPlaces,
      onTypeChanged, setSwiperInstance, onSlideChanged,
      startListening, stopListening, iPhoneNativeApp,

      // icons
      add, close, search, navigate, arrowBack, bookmarksOutline, timeOutline,
      swapVerticalOutline, trashOutline, arrowForward, location, mic, stopCircleOutline,

      // variables
      loading, currUser, userLatLong,
      places, placeTypes,
      typeFilter, segmentBtns,
      parentPath,
      searchKeyword, placeSearchBar, isSearching,
      topElRef, delayLoad, isListening,
    }
  }
}
</script>

<style scoped>
  .place-searchbar {
    margin: 10px auto;
    padding-top: 0;
    padding-bottom: 0;
    height: 40px;
  }
</style>